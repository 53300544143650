<template>
  <div>
    <v-card :loading="isLoading">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">
            <span>{{sheet.header.sth_name}}</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-chip
          label
          :color="sheet.header.sth_status|salarySheetStatusColor"
          outlined
        >{{ sheet.header.sth_status|salarySheetStatusText }}</v-chip>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-row>
          <v-col cols="12" xs="12" sm="4" md="4" lg="4">
            <v-text-field
            :readonly="!(sheet.header.sth_status==1||sheet.header.sth_status==2)"
              class="d-input-no-foot"
              v-model="sheet.header.sth_name"
              dense
              label="Tên bảng tính"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="4" lg="4">
            <v-text-field
            :readonly="!(sheet.header.sth_status==1||sheet.header.sth_status==2)"
              class="d-input-no-foot"
              v-model="sheet.header.sth_from"
              type="date"
              dense
              label="Từ"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="4" md="4" lg="4">
            <v-text-field
            :readonly="!(sheet.header.sth_status==1||sheet.header.sth_status==2)"
              class="d-input-no-foot"
              v-model="sheet.header.sth_to"
              type="date"
              dense
              label="Đến"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-list-item>
      <v-card-actions>
        <v-btn color="blue" :loading="isLoading" @click="fetchData()" dark>
          <v-icon small>fas fa-sync-alt</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" v-if="sheet.header.sth_status==1||sheet.header.sth_status==2" :loading="isLoading" @click="updateSheetHeaderStatusClick(0)">Xóa</v-btn>
        <v-btn color="primary" v-if="sheet.header.sth_status==1" :loading="isLoading" @click="updateSheetHeaderClick()">Sửa thông tin</v-btn>
        <v-btn
          color="teal"
          dark
          :loading="isLoading"
          v-if="sheet.header.sth_status==2"
          @click="updateSheetHeaderStatusClick(3)"
        >Chốt sổ</v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
    <v-card-title style="margin-top:20px">
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Tìm tên nhân viên"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  </v-card>
    <v-data-table
      :search="search"
      :loading="isLoading"
      :headers="tableHeaders"
      :items="sheet.lines"
      item-key="id"
      class="elevation-1"
    >

      <template v-slot:top>
        <div style="padding:10px;">
          <div>
            <v-btn color="primary" v-if="sheet.header.sth_status==1||sheet.header.sth_status==2" :loading="isLoading" @click="calSalarySheetClick()">Tính lương</v-btn>

            <v-btn
              style="float:right"
              class="text-right"
              color="primary"
              :loading="isLoading"
              v-if="sheet.header.sth_status==1||sheet.header.sth_status==2"
              @click="updateSheetLinesClick()"
            >Lưu lại</v-btn>
          </div>
          <div style="font-size: 14px;margin-top:10px;margin-left:30px">
            <b>Chi tiết bảng lương:</b> •Tổng lương: {{sumS|numberF0}} •Tổng thưởng/phạt: {{sumOther|numberF0}} •Tổng cộng: {{total|numberF0}}
          </div>
        </div>
      </template>
      <template v-slot:item.shifts_num="{ item }">
        <span>{{item.shifts_num}}</span>
      </template>
      <template v-slot:item.sum_w_hours_diff="{ item }">
        <template v-if="item.sum_w_hours_diff">
          <!-- <span>{{item.sum_w_hours_diff}}h ({{item.min_w_hours_diff}}>{{item.max_w_hours_diff}})</span> - -->
          <span style="color:#009688">
            <b>{{item.sum_w_hours}}h</b>
            <!-- ({{item.min_w_hours}}>{{item.max_w_hours}}) -->
          </span>
        </template>
      </template>
      <template v-slot:item.w_salary_cal="{ item }">
        <v-text-field
          v-model="item.w_salary_edt"
          type="number"
          style="    width: 100px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          dense
          placeholder="Nhập số"
        ></v-text-field>
        <template v-else>
          <span>
            {{item.w_salary_cal|numberF0}}
            <span
              style="color:#009688"
              v-if="item.w_salary_cal!=item.w_salary_edt"
            >-{{item.w_salary_edt|numberF0}}</span>
          </span>
        </template>
      </template>
      <template v-slot:item.s_type="{ item }">
        <template v-if="item.s_type">
          <span>{{item.s_value|numberF0}}/{{item.s_type|salaryTypeText}}</span>
        </template>
      </template>
      <template v-slot:item.other_value="{ item }">
        <v-text-field
          v-model="item.other_value"
          type="number"
          style="    width: 100px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          dense
          placeholder="Nhập số"
        ></v-text-field>
        <template v-else>
          <span>{{item.other_value|numberF0}}</span>
        </template>
      </template>
      <template v-slot:item.total="{ item }">
        <template>
          <span>{{(Number(item.w_salary_edt)+Number(item.other_value))|numberF0}}</span>
        </template>
      </template>
      <template v-slot:item.workdays_num="{ item }">
        <template>
          <span>{{(item.workdays_num)|numberFR}}/{{(item.work_day_const)|numberFR}}</span>
        </template>
      </template>
      <template v-slot:item.admin_note="{ item }">
        <v-textarea
          v-model="item.admin_note"
          style="    width: 150px;"
          class="d-input-no-foot"
          v-if="item.isEditing"
          dense
          placeholder="Ghi chú"
        ></v-textarea>
        <v-tooltip v-else>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <span v-if="item.admin_note">{{item.admin_note.substring(0,15)}}</span>
              <span v-else>{{item.admin_note}}</span>
            </span>
          </template>
          <span>{{item.admin_note}}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.w_ac="{ item }">
        <v-btn-toggle dense color="#033">
          <template v-if="item.isEditing">
            <v-btn
              :loading="item.isLoading"
              depressed
              small
              color="green"
              @click="createOVal(item)"
              text-color="white"
            >
              <i class="fas fa-check" style="color:white"></i>
            </v-btn>
            <v-btn
              :loading="item.isLoading"
              @click="undoOVal(item)"
              depressed
              small
              color="orange"
              text-color="white"
            >
              <i class="fas fa-undo-alt" style="color:white"></i>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              :loading="item.isLoading"
              @click="item.isEditing=true"
              depressed
              small
              color="blue"
              text-color="white"
            >
              <i class="far fa-edit" style="color:white"></i>
            </v-btn>
          </template>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  getSalarySheetDetail,
  calSalarySheet,
  updateSheetLines,
  updateSheetHeader,
  updateSheetHeaderStatus
} from "@/api/salary";
import { showToast } from "@/utils/index";
export default {
  created() {
    this.fetchData();
  },
  computed: {
    sId() {
      return this.$route.params.id;
    },
    sumS(){
      let sum = 0;
      this.sheet.lines.forEach(e=>{
        sum+=Number(e.w_salary_edt);
      })
      return sum;
    },
    sumOther(){
      let sum = 0;
      this.sheet.lines.forEach(e=>{
        sum+=Number(e.other_value);
      })
      return sum;
    },
    total(){
      let sum = 0;
      this.sheet.lines.forEach(e=>{
        sum+=(Number(e.w_salary_edt)+Number(e.other_value));
      })
      return sum;
    }
  },
  methods: {

    updateSheetHeaderStatusClick(status) {
      let msg = "";
      if (status == 0) {
        msg = "Bạn có chắc muốn xóa bảng này, bạn sẽ không thể hoàn tác?";
      }
      if (status == 3) {
        msg = "Bạn có chắc muốn chốt bảng tính này, bạn sẽ không thể sửa lại?";
      }
      this.$confirm(msg, { color: "warning", title: "Warning" }).then(res => {
        if (res) {
          updateSheetHeaderStatus({
            id: this.sId,
            status: status
          })
            .then(() => {
              this.sheet.header.sth_status = status;
              this.isLoading = false;
              showToast(this, "success", "Đã lưu");
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    updateSheetHeaderClick() {
      this.isLoading = true;
      updateSheetHeader({
        id: this.sId,
        name: this.sheet.header.sth_name,
        from: this.sheet.header.sth_from,
        to: this.sheet.header.sth_to
      })
        .then(() => {
          this.isLoading = false;
          showToast(this, "success", "Đã lưu");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    updateSheetLinesClick() {
      this.isLoading = true;
      updateSheetLines({
        header_id: this.sId,
        lines: this.sheet.lines
      })
        .then(() => {
          this.isLoading = false;
          this.sheet.header.sth_status =2;
          showToast(this, "success", "Đã lưu");
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    createOVal(e) {
      e.o_w_salary_edt = e.w_salary_edt;
      e.o_admin_note = e.admin_note;
      e.o_other_value = e.other_value;
      e.isEditing = false;
    },
    undoOVal(e) {
      e.w_salary_edt = e.o_w_salary_edt;
      e.admin_note = e.o_admin_note;
      e.other_value = e.o_other_value;
      e.isEditing = false;
    },
    fetchData() {
      this.isLoading = true;
      getSalarySheetDetail({
        id: this.sId
      }).then(res => {
        this.isLoading = false;
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Lương", route: "/salary/salary-sheets" },
          { title: "Bảng tính lương", route: "/salary/salary-sheets" },
          {
            title: res.data.header.sth_name,
            route: "/salary/salary-sheets/" + this.sId
          }
        ]);
        res.data.lines.forEach(e=>{
          this.createOVal(e);
        })
        this.sheet = res.data;
      });
    },
    calSalarySheetClick() {
      this.isLoading = true;
      calSalarySheet({
        id: this.sheet.header.id
      }).then(res => {
        res.data.forEach(e => {
          this.createOVal(e);
        });
        this.sheet.lines = res.data;
        this.isLoading = false;
      });
    }
  },
  data() {
    return {
      isLoading: false,
      sheet: {
        header: {},
        lines: []
      },
      search:'',
      tableHeaders: [
        { text: "Nhân viên", value: "user_fullname" },
        { text: "Số ca", value: "shifts_num", align: "center" },
        { text: "Số giờ", value: "sum_w_hours_diff", align: "center" },
        { text: "Số ngày", value: "workdays_num", align: "center" },
        { text: "Loại lương", value: "s_type", align: "center" },
        { text: "Lương tính được", value: "w_salary_cal", align: "center" },
        { text: "Thưởng/phạt", value: "other_value", align: "center" },
        { text: "Tổng", value: "total", align: "center" },
        { text: "Ghi chú", value: "admin_note" },
        { text: "Actions", value: "w_ac", align: "center" }
      ]
    };
  }
};
</script>